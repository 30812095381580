import { getOrCreateProcedure, type IProcedureOptions, type Procedure } from 'o365-modules'
import { ref } from 'vue';

export const procedureStores = new Map<string, Procedure>();

export function getOrCreateReactiveProcedure<T extends object = any>(pOptions: IProcedureOptions): Procedure<T> {
    if (!procedureStores.has(pOptions.id)) {
        procedureStores.set(pOptions.id, ref(getOrCreateProcedure(pOptions)).value);
    }
    return procedureStores.get(pOptions.id)!;
}
